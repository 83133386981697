import React from "react"
import instagramLogo from "../svg/instagram.svg"

export default () => (
  <a
    href="https://www.instagram.com/nickdoesfood"
    target="_blank"
    rel="noreferrer noopener"
    className="instagram-link"
  >
    <img width="24" src={instagramLogo} alt="Instagram" />
  </a>
)
